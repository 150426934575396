.app {
  font-family: "Arial", sans-serif;
  color: #ff6600;
  background-color: #181818;
  padding: 2rem;
  height: 94vh;
  max-width: 500px;
  margin: 0 auto;
}

.app.light {
  background-color: #cacaca;
  color: #333;
}

.container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.container-title h1 {
  font-size: 1.8rem;
  margin: 0;
  font-weight: 700;
}

.dark-mode-button {
  cursor: pointer;
  width: 50px;
  height: 25px;
  background-color: #444;
  border-radius: 15px;
  position: relative;
}

.dark-mode-button .on,
.dark-mode-button .off {
  border-radius: 50%;
  position: absolute;
  top: 0;
  transition: 0.3s ease;
}

.dark-mode-button .on {
  left: 25px;
}

.dark-mode-button .off {
  left: 0;
}

.item-container {
  background-color: #333;
  padding: 1rem;
  border-radius: 8px;
}

.item-container.light-container {
  background-color: #f9f9f9;
}

.each-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-bottom: 1px solid #444;
  font-weight: 600;
}

.each-item:last-child {
  border-bottom: none;
}

.each-item.completed {
  text-decoration: line-through;
  color: #888;
}

.left {
  font-size: 1.1rem;
}

.right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.add-item-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 1rem 0;
}

.add-item-section input {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.time-container {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 2%;
}

.add-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #fff;
  background-color: #00af1d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button {
  font-weight: 600;
}

button:hover {
  scale: 1.05;
}

.start-button,
.stop-button,
.pause-button,
.continue-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.stop-button {
  background-color: #dc3545;
}

.pause-button {
  background-color: #ffc107;
}

.continue-button {
  background-color: #17a2b8;
}

.congrats-message {
  font-size: 1.5rem;
  color: #28a745;
  text-align: center;
  margin-top: 2rem;
}

.stop-pause-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 1rem;
}
